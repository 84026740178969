import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyByf1OMMKGcve4mDdIGuQAD-CTA1aH-7xo",
  authDomain: "flashmed-c4cbd.firebaseapp.com",
  databaseURL: "https://flashmed-c4cbd.firebaseio.com",
  projectId: "flashmed-c4cbd",
  storageBucket: "flashmed-c4cbd.appspot.com",
  messagingSenderId: "940176756553",
  appId: "1:940176756553:web:f7eae0ec6c2fd7b8efd498",
  measurementId: "G-GT19PW53KP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
