import { useState, forwardRef, useRef } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import WaveLoading from './components/Loading';
import './App.css';
import { useLocation } from 'react-router-dom';

const ActivationKeyInput = forwardRef(({initialValue}, ref) => {
    const [value, setValue] = useState(initialValue || '');
    if (initialValue) return <input readonly value={value} maxLength="4" type="text" className="activation-input opacity-60" ref={ref} autoCorrect="off" autoCapitalize="off"/>;
    return <input onChange={(e) => setValue(e.target.value.toUpperCase())} value={value} maxLength="4" type="text" className="activation-input" ref={ref} autoCorrect="off" autoCapitalize="off"/>;
})

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
 
function App(props) {
    const [emailError, setEmailError] = useState();
    const [passwordError, setPasswordError] = useState();
    const [success, setSuccess] = useState(false);
    const [entitlement, setEntitlement] = useState();
    const [expiration, setExpiration] = useState();
    const [cardError, setCardError] = useState();
    const [loading, setLoading] = useState(false);
    const emailRef = useRef();
    const passwordRef = useRef();
    const activationKeyRef1 = useRef();
    const activationKeyRef2 = useRef();
    const activationKeyRef3 = useRef();
    const activationKeyRef4 = useRef();
    const keyRef = useRef();
    const auth = getAuth();
    const location = useLocation();
    const activationKey = (location.pathname.substring(1));
    
    const handleSubmit = async (e) => {
        setEmailError();
        setPasswordError();
        setCardError();
        if (!loading) {
            setLoading(true);
            e.preventDefault();
            const email = emailRef.current.value.replace(/\s/g, "");
            const password = passwordRef.current.value.replace(/\s/g, "");
            await signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;
                const uid = user.uid;
                const id = `${activationKeyRef1.current.value}-${activationKeyRef2.current.value}-${activationKeyRef3.current.value}-${activationKeyRef4.current.value}`
                const key = keyRef.current.value.replace(/\s/g, "");
                const data = {
                    uid, 
                    id,
                    key
                }
                await fetch('https://europe-west3-flashmed-c4cbd.cloudfunctions.net/giftcard-activate', {
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    mode: 'cors',
                    method: 'POST',
                    body: JSON.stringify(data)
                })
                .then((response) => {
                    return response.json();
                })
                .then((result) => {
                    if (result.error) {
                        let message;
                        switch (result.error.code) {
                            case 'invalid-credentials': message = "Los códigos introducidos no son válidos"; break;
                            case 'multiple-results': message = "Por favor, ponte en contacto con el equipo de soporte para activar esta tarjeta"; break;
                            case 'already-redeemed': message = "Esta tarjeta ya se ha canjeado en el pasado"; break;
                            case 'expired-giftcard': message = "La tarjeta está caducada"; break;
                            case 'user-not-found': message = "Por favor, ponte en contacto con el equipo de soporte para activar esta tarjeta"; break;
                            case 'current-active-subscription': message = "Tu cuenta ya tiene una suscripción activa"; break;
                            case 'internal-error': message = "Por favor, ponte en contacto con el equipo de soporte para activar esta tarjeta"; break;
                            default: message = "Por favor, ponte en contacto con el equipo de soporte para activar esta tarjeta";
                        }
                        setCardError(message);
                    } else {
                        const { entitlement, expiration } = result;
                        setEntitlement(capitalize(entitlement));
                        setExpiration(new Date(expiration).toLocaleDateString("es"));
                        setSuccess(true);
                    }
                })
                .catch((error) => {
                    setCardError("Por favor, ponte en contacto con el equipo de soporte para activar esta tarjeta");
                });
                setLoading(false);
            })
            .catch((error) => {
                const errorCode = error.code;
                switch (errorCode) {
                    case 'auth/invalid-email': setEmailError('La dirección email no es válida'); break;
                    case 'auth/user-disable': setEmailError('Usuario deshabilitado temporalmente'); break;
                    case 'auth/user-not-found': setEmailError('No se ha encontrado una cuenta con este email'); break;
                    case 'auth/wrong-password': setPasswordError('Contraseña incorrecta'); break;
                    default: setEmailError('Error al ingresar en tu cuenta');
                }
                setLoading(false);
            })
        }
    }
    let actInit1, actInit2, actInit3, actInit4;
    if (activationKey.length === 16) {
        actInit1 = activationKey.substring(0, 4);
        actInit2 = activationKey.substring(4, 8);
        actInit3 = activationKey.substring(8, 12);
        actInit4 = activationKey.substring(12, 16);
    }

    const hasEmailError = emailError && emailError !== '';
    const hasPasswordError = passwordError && passwordError !== '';
    const hasCardError = cardError && cardError !== '';

    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-left-subcontainer">
                    <div className="logo-wrapper">
                        <img className="logo" src="https://flashmedapp.com/wp-content/uploads/2021/02/FlashMed-Redondeado.png" alt=""/>
                        <span className="logo-text">FlashMed</span>
                    </div>
                    <img className="big-image rounded-xl shadow-lg" src="https://flashmedapp.com/wp-content/uploads/2021/11/Tarjeta-Regalo-FlashMed-Claro.png" alt=""/>
                </div>
                <div className="login-right-subcontainer">
                    {
                        success
                        ? <div className="h-full flex flex-col justify-center items-center">
                            <div className="bg-green-50 rounded-full h-40 w-40 flex flex-row items-center justify-center">
                                <div className="bg-green-100 rounded-full h-32 w-32 flex flex-row items-center justify-center">
                                    <div className="bg-green-300 text-white rounded-full h-20 w-20 flex flex-row items-center justify-center">
                                        <svg xmlns="https://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                            <h2 className="mt-6 text-2xl font-semibold text-gray-700">FlashMed {entitlement}</h2>
                            <span className="text-gray-400">Activado hasta el {expiration}</span>
                        </div>
                        : <form action="">
                            <div className="information-tab">
                            <img className="only-mobile logo" src="https://flashmedapp.com/wp-content/uploads/2021/02/FlashMed-Redondeado.png" alt=""/>
                                <span className="only-mobile logo-text">FlashMed</span>
                                <p className="label">
                                    Introduce los datos para activar la tarjeta de regalo en tu cuenta de FlashMed
                                </p>
                            </div>
                            <p className="label">
                                Email
                            </p>
                            <input type="text" className="email-input" ref={emailRef} autoCorrect="off" autoCapitalize="off" />
                            {hasEmailError ? <p className={`error-text ${hasEmailError ? '' : 'hidden'}`}>{emailError}</p> : ''}
                            <p className="label">
                                Contraseña
                            </p>
                            <input type="password" className="password-input" ref={passwordRef} autoCorrect="off" autoCapitalize="off" />
                            {hasPasswordError ? <p className={`error-text ${hasPasswordError ? '' : 'hidden'}`}>{passwordError}</p> : ''}
                            <div className={`border-2 rounded border-dashed ${hasCardError ? 'border-red-700' : 'border-gray-200'} px-6 pb-6 mb-10 pt-2 mt-8`}>
                                <p className="label">
                                    Código de activación
                                </p>
                                <div className="flex flex-row gap-2 items-center">
                                    <ActivationKeyInput initialValue={actInit1} ref={activationKeyRef1} />
                                    <span className="text-gray-300 font-bold">-</span>
                                    <ActivationKeyInput initialValue={actInit2} ref={activationKeyRef2} />
                                    <span className="text-gray-300 font-bold">-</span>
                                    <ActivationKeyInput initialValue={actInit3} ref={activationKeyRef3} />
                                    <span className="text-gray-300 font-bold">-</span>
                                    <ActivationKeyInput initialValue={actInit4} ref={activationKeyRef4} />
                                </div>
                                <p className="label">
                                    Clave de la tarjeta
                                </p>
                                <input type="text" className="key-input" ref={keyRef} autoCorrect="off" autoCapitalize="off"/>
                                {hasCardError ? <p className={`pt-4 error-text ${hasCardError ? '' : 'hidden'}`}>{cardError}</p> : ''}
                            </div>
                            <div className="login-button-wrapper">
                                { loading
                                    ? <div className="loading-container">
                                        <WaveLoading />
                                    </div>
                                    : <button className="highlight-button" onClick={handleSubmit}>
                                        Activar tarjeta
                                    </button>
                                }
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div>
    )
}

export default App;